<template>

  <AdvHeader v-if="diaryData?.item_user?.id && !useAuth().isOwner(diaryData?.item_user?.id)" />

  <div :class="{'pb': true, 'dia': true, 'is-modal': props.modalId}" ref="diaryPageEl" tabindex="0" >  


    <!-- <div v-if="true" style="position: absolute;z-index: 2;background-color: #00000040; width: 100%;">
      <SkDiaryOpenedV2 />
    </div> -->


    <template v-if="diaryData && diaryData.id && diaryData.item_user?.id">      

      <div class="dat_head">
        
        <DiarywideViewHead 
          :diary="diaryData"
          @remove-diary="handleRemoveDiary"
          @unpublish-diary="handleUnpublishDiary"
          @publish-diary="handlePublishDiary"
          @follow-diary="handleFollowDiary"
          @unfollow-diary="handleUnfollowDiary"
          @approve="handleApproveDiary"
          @unapprove="handleApproveDiary"
        />

        <DiaryViewConditions 
          :diary="diaryData"
        /> 
      </div>


      <NuxtPage
        v-if="isEditMode"
        :diary="diaryData"
        @create-week-modal="handleCreatingWeekModal"
        @remove-week="handleRemoveWeek"
        @update-week="handleUpdateWeek"
        @cancel-edit-week="handleCancelEditWeek"
      />


      <template v-else>

        <ClientOnly>
          <Teleport to="#teleport">
            <div :class="{'dat_weeks': true, 'is-modal': props.modalId}">
              <DiarywideViewWeeks 
                :weeks="diaryData.items_week"   
                :owner="diaryData.item_user.id"   
                :link="diaryData.link"
                :active-week-id="weekActive?.id"
                :root-week-id="rootWeekId"
                @choose="interactWeek" 
                @create="handleCreatingWeekModal()"
              />
            </div>
          </Teleport>
        </ClientOnly>
 




        <DiarywideViewGalleryWeeks
          :diary="diaryData"
          :week-active="weekActive"
          :comments="commentsData"
          :is-modal="props.modalId ? true : false"
          @interact-week="interactWeek"
          @leave-week="leaveWeek"
          @remove-week="handleRemoveWeek"
          @share-week="handleShareWeek"
        />

        <WeekwideViewFazaEmpty
          v-if="!diaryData?.items_week?.length && useAuth().isOwner(diaryData.item_user.id)"
          :is-owner="useAuth().isOwner(diaryData.item_user.id)"
          @create="handleCreatingWeekModal"
        />
    

        <div 
          v-if="diaryData?.id && lastWeekId && useAuth().isOwner(diaryData.item_user.id)"
          class="problems"
        >

          <div class="dot-ttl">
            New Grow Question
          </div>

          <QuestionShortForm
            v-if="useAuth().isOwner(diaryData.item_user.id) && lastWeekId"
            :diary="diaryData.id"
            :week="lastWeekId"
          />

        </div>

        <ClientOnly>
          <LazyAiWidgetDiary 
            v-if="(useAuth().getAccess('view_extra') || useAuth().isPremium()) && lastWeekId"
            :diary-id="diaryData.id"
            :week-id="lastWeekId"
          />
        </ClientOnly>

        <br>

        <CommentWidget
          v-if="diaryData?.id && lastWeekId"
          :title="$t('diary_info_comments')"
          :type="'diary'"
          :content="lastWeekId"
          :exists-toggler-diary-week="false"
        />


        <DiarywideViewTheEnd 
          v-if="!useAuth().isOwner(diaryData.item_user.id) && diaryData?.max_faza === WeekFaza.Harvest"
        />


        <DiarywideViewFollowAfter 
          v-if="!useAuth().isOwner(diaryData.item_user.id)"
          :diary="diaryData"
        />

        

        <DiarywideViewSwitchView />



        <!-- <DiarywideViewSimilarDiaries
          :class="{'hidden': !showComments}"
          v-if="diaryData?.id"
          :diary-id="diaryData?.id"
        /> -->



        <!-- <div :class="{'hidden': !showComments}"> -->


        <!-- </div> -->



        <ClientOnly>
          <Teleport to="#teleport">
            <DiarywideViewChooseWeekFaza 
              v-if="showCreateWeekModal"
              :diary="diaryData"
              @close="closeCreateWeekModal()"
            />
          </Teleport>
        </ClientOnly>

      </template>




    </template>

    <template v-else>
      <SkDiaryOpenedV2 />
    </template> 

    <WeekwideViewContextMenu 
      :diary="diaryData"
      :week="weekActive" 
      @remove-diary="handleRemoveDiary"
      @publish-diary="handlePublishDiary"
      @unpublish-diary="handleUnpublishDiary"
      @add-week="handleCreatingWeekModal"
      @like-week="handleLikeWeek"
      @unlike-week="handleUnlikeWeek"
      @follow-diary="handleFollowDiary"
      @unfollow-diary="handleUnfollowDiary"
      />
 

    <GeneralShareModal
      v-if="shareShow"
      :showed="shareShow"
      @close="shareShow = false"
      />
      



    <WeekEditChooseCover
      :diary-id="diaryData?.id"
      :list="activeCoverChooseWeekPhotos"
      :active="activeCoverChoose"
      @close="closeCoverModal()"
    />

      

  </div>
</template>




<script setup lang="ts">

import type { Diary, Week } from '@/types/diary'
import { WeekFaza } from '@/types/diary'


const route = useRoute();
const router = useRouter();
const { $api, $ga, $helper, $popup, $head , $follow} = useNuxtApp();

interface PageProps {
  modalId?: number,
  activeWeekChoose?: string
}
const props = defineProps<PageProps>()

const link = ref(props.modalId ?? route.params.id);
const shareShow = ref(false);
const isLoaded = ref(false);
const commentShow = ref(false);
const commentShowWeekId = ref(null);
const diaryPageEl = ref<HTMLElement | null>(null);
const activeCoverChoose = ref(false);
const activeCoverChooseWeekPhotos = ref([]);
const nutrientsShow = ref(false);
const setupShow = ref(false);
const reviewShow = ref(false);
const weekActive = ref<Week | null>(null);
const showCreateWeekModal = ref<Boolean>(props.activeWeekChoose ? true : false);
const diaryId = ref(props.modalId ?? $helper.extractLinkId(route.params['id']));
const routeWeekId = ref(route.params['week'] ?? null);
const light = ref(route.query['light'] ?? null);
 
const isEditMode = computed(() => {
  return route.matched.some((m) => m.path.indexOf('/edit') >= 0 || m.path.indexOf('/smoke') >= 0);
});

// watch(props, (new_props, old_modalId) => {
//   link.value = new_props.modalId ?? route.params.id;
//   diaryId.value = new_props.modalId ?? $helper.extractLinkId(route.params['id']);
//   routeWeekId.value = route.params['week'] ?? null;
//   weekActive.value = null;
//   diaryData.value = loadData(diaryId.value);
// }, {deep: true})

 

function closeCoverModal() {
  activeCoverChoose.value = false;
  showFinishedDiaryModal();
}

function showFinishedDiaryModal() {

  var count_harvests = diaryData.value?.items_week?.filter( (w) => { return w.faza == 2 }).length;
  var count_seeds = diaryData.value?.items_seed?.length;

  if(count_harvests == count_seeds ){
    activeFinishedChoose.value = true;
  }

}

const handleShareWeek = function() {
  shareShow.value = true;
}

const toggleSetup = function() {
  setupShow.value = !setupShow.value;
}
provide('actionsToggleSetupEvent', toggleSetup);


const toggleReview = function() {
  reviewShow.value = !reviewShow.value;
}
provide('actionsToggleReviewEvent', toggleReview);




const toggleNutrients = function() {
  nutrientsShow.value = !nutrientsShow.value;
}
provide('actionsToggleNutrientsEvent', toggleNutrients);


watch(route, (new_route, old_route) => {
  // nextTick(() => {
    // console.log('new_route.params');
    // console.log(new_route.params);
    routeWeekId.value = new_route.params['week'] ?? null;
    // setActiveWeek(routeWeekId.value);
  // })
}, {deep: true})


const rootWeekId = computed(() => {
  var id = null;
  if(diaryData.value?.items_week){
    for (const [wkey, w] of diaryData.value.items_week.entries()) {
      if(wkey == 0)
        id = w.id;
    }
  }
  return id;
})


const lastWeekId  = computed(() => {
  if(diaryData.value?.items_week?.length)
    return diaryData.value.items_week[diaryData.value.items_week.length - 1].id;
  return null;
})

const showComments = computed(() => {
  for (const m of route.matched)
    if (m.path.indexOf('edit') >= 0)
      return false;   
  return true;
});

const leaveWeek = function(week: Week) {
  if(!weekActive.value?.id)
    return;
  if(!week?.id){
    return;
  }
  if(weekActive.value && weekActive.value.id === week.id){
    weekActive.value = null;
  }
  setPathWeekActive();
}

const interactWeek = function(week: Week) {
  // router.push({
  //   path: week.link,
  //   force: false,
  //   state: {
  //     week: week.id
  //   }
  // });
  // console.log('week', week);

  // $ga.pageView();
  
  weekActive.value = week;
  setPathWeekActive(); 
}


const scrollToHashWeek = function() {
  let hash = window.location.hash;
  if(hash){
    let weekId = hash.split('#weeks/')[1];
    if(weekId && diaryData.value){
      const week = diaryData.value.items_week.find((w) => w.id === parseInt(weekId));
      if(week){
        interactWeek(week);
      }
    }
  }
}

const scrollToWeek = function(week: Week) {
  const weekEl = document.querySelector('[data-week-id="'+ week.id.toString()+'"]');
  if(weekEl){
    weekEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}

const isFirstWeek = function(diary: Diary, week: Week) {
  if(!diary?.items_week)
    return false;
  return diary.items_week[0].id === week.id;
}

const setPathWeekActive = function() {


  if(weekActive.value && !isFirstWeek(diaryData.value, weekActive.value)){
    useRouteHistory().replace(diaryData.value.link + '#weeks/' + weekActive.value.id);
  }else{
    useRouteHistory().replace(diaryData.value.link);
  }
}
 

function closeCreateWeekModal() {
  showCreateWeekModal.value = false;
}
 

function redirectToWeek(editWeekId: number) {
  // nextTick(() => {
    if(!editWeekId || editWeekId === rootWeekId.value){
      router.replace({
        path: '/diaries/' + diaryData.value.link
      });
    }else{
      router.replace({
        path: '/diaries/' + diaryData.value.link + '#weeks/' + editWeekId
      });
    }

  // })

}
 

const loadData = async (id) => {
  console.log('load diary id:', id)
  let data = await $api.getDiary(id);  
  isLoaded.value = true; 
  return data;
}

const loadComments = async (id) => {
  const response = await $api.getCommentsSummary(
    diaryId.value,
    'diary'
  )  
  return response;  
}


const setActiveWeekFromRoute = () => {
  console.log('setActiveWeekFromRoute', routeWeekId.value);
  if(routeWeekId.value){
    const week = diaryData.value.items_week.find((w) => w.id === routeWeekId.value);
    if(week){
      interactWeek(week);
    }
  }
}


const { status: statusDiaryData,  data: diaryData } = await useLazyAsyncData('diaryData', async () => await loadData(diaryId.value) as Diary, {
  deep: true,
  immediate: true
})


const { status: statusCommentsData,  data: commentsData } = await useLazyAsyncData('commentsData', async () => await loadComments(diaryId.value))

 


watch(router.currentRoute, (newCurrentRoute, oldCurrentRoute) => {
  useHead(useNuxtApp().$head.getForDiary(diaryData.value, weekActive.value, diaryData.value?.is_deindex));
}, {deep: true})


useHead(useNuxtApp().$head.getForDiary(diaryData.value, weekActive.value, diaryData.value?.is_deindex));



// if(diaryData.value){
//   setActiveWeekFromRoute();
// }

// onUnmounted(() => {
//   nextTick(() => {
//     setActiveWeekFromRoute();
//   })
// })

 

onUnmounted(() => {
  if(!diaryData.value)
    return;
  diaryData.value = null;
  weekActive.value = null;
})

watch(diaryData, async ( new_diaryData) => {
  useHead(useNuxtApp().$head.getForDiary(diaryData.value, weekActive.value, diaryData.value?.is_deindex));
})

onMounted(() => {
  useHead(useNuxtApp().$head.getForDiary(diaryData.value, weekActive.value, diaryData.value?.is_deindex));
})

// theme margins
onMounted(() => {
  usePageBottomMargin().value = 90;
})

onUnmounted(() => {
  usePageBottomMargin().value = 0;
})




// events keydown

const pressKey = (event: KeyboardEvent) => {

  const active = document.activeElement;
  if (active && (active.tagName.toLowerCase() === 'input' ||  active.tagName.toLowerCase() === 'textarea')) {
    return;
  }


  let triggered = false;
  
  
  if(event.key === "Escape") {
    useModalsDiaryShow().value = false;
    useModalsDiary().value = null;
    triggered = true;
  }

  if (event.key === "ArrowRight" || event.key === "ArrowDown") {    
    if(!weekActive.value){
      interactWeek(diaryData.value.items_week[0])
    }

    const currentIndex = diaryData.value.items_week.findIndex(
      (w) => w.id === weekActive.value.id
    )
    const nextIndex = currentIndex + 1
    if (nextIndex < diaryData.value.items_week.length) {
      interactWeek(diaryData.value.items_week[nextIndex])
    }

    triggered = true;
  }

  if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
    if(!weekActive.value){
      interactWeek(diaryData.value.items_week[0])
    }
    const currentIndex = diaryData.value.items_week.findIndex(
      (w) => w.id === weekActive.value.id
    )
    const prevIndex = currentIndex - 1
    if (prevIndex >= 0) {
      interactWeek(diaryData.value.items_week[prevIndex])
    }

    triggered = true;
  }
  
  if(triggered){
    event.preventDefault()
    event.stopPropagation()
  }
  
  // event.stopImmediatePropagation()

}

onMounted(() => {
  nextTick(() => {
    if(import.meta.client){
      window.focus();
      window.addEventListener('keydown', pressKey);
    }
  })
})

onUnmounted(() => {
  if(window){
    window.removeEventListener('keydown', pressKey);
  }
})




// NEW 


function handleCreatingWeekModal() {
  showCreateWeekModal.value = true;
}

async function handleUpdateWeek(editWeekId: number) {  
  diaryData.value = await $api.getDiary(diaryData.value.id)  
  redirectToWeek(editWeekId); 

  console.log('handleUpdateWeek', editWeekId);
  console.log('handleUpdateWeek', diaryData.value.items_week.find((w) => w.id === editWeekId));
  activeCoverChoose.value = true;
  activeCoverChooseWeekPhotos.value = diaryData.value.items_week.find((w) => w.id === editWeekId).items_photo;
}
 
function handleCancelEditWeek(editWeekId: number) {
  console.log('handleCancelEditWeek', editWeekId);
  redirectToWeek(editWeekId);
}


const handleRemoveDiary = () => {
  useDiary().remove(diaryData.value.id);
}

const handleUnpublishDiary = () => {
  useDiary().unpublish(diaryData.value);
  // diaryData.value.is_hidden = 1;
}

const handlePublishDiary = () => {
  useDiary().publish(diaryData.value);
  // diaryData.value.is_hidden = 0;
}

const handleLikeWeek = () => {

}

const handleUnlikeWeek = () => {

}

const handleFollowDiary = () => {
  // useDiary().follow(diaryData.value);
}

const handleUnfollowDiary = () => {
  // useDiary().unfollow(diaryData.value);
}


const handleRemoveWeek = (week: Week) => {
  useDiary().removeWeek(diaryData.value, week);
}

const handleApproveDiary = (state, brand_id) => {
  state ? useDiary().approve(diaryData.value, brand_id) : useDiary().unapprove(diaryData.value, brand_id);
}


onMounted(() => {
  $ga.eventGA('diary_redesign', 'view', 'v2');
  if(props.modalId){
    $ga.pageView();
  }
  nextTick(() => {
    scrollToHashWeek();
  })
})




</script>


<style scoped>

.scrl{
  overflow-y: auto;
  max-height: calc(100vh - 130px);
}

.dia{
  display: flex;
  align-items: flex-start;
  gap: 0 1rem;
  flex-wrap: wrap;
  max-width: 935px!important;
  margin-bottom: 10rem!important;
}
.gal{ 
  width: calc(100%);
  margin-bottom: 100px;
}
.dat_head{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0 1rem;
  margin-bottom: 2rem;
}

.dat_weeks{
  position: fixed;
  bottom: 5px;
  max-width: 70%;
  width: 100%;
  left: 0;
  padding: 10px;
  z-index: 101;
  right: 0;
  left: 40%;
  transform: translateX(-40%);
}

.offnav .dat_weeks{
  margin-left: 60px;
}

.onnav .dat_weeks{
  margin-left: 180px;
}

.onnav .dat_weeks.is-modal{
  margin-left: 40px;
}

.is-modal .dat_weeks{
  margin-left: 0!important;
  left: 50%;
  transform: translateX(-50%);
}

.week-box{
  position: sticky;
  top:50px;
  max-height: calc(100vh - 30px);  
}

.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.gal .report_days_sticky{
  display: none;
}

.gal .report_header{
  display: none;
}

  
@container pb (max-width: 600px) {

  .gal{

    width: 100%;
  }

  .gal .report_days_sticky{
    display: flex;
  }

  .gal .report_days_sticky{
    margin-left: -2rem;
    width: calc(100% + 4rem);
    padding: 0.5rem 2rem;
  }

  .gal .report_header{
    display: flex;
  }
  .dat{
    display: none
  }
}

@media (max-width: 768px) {

  .dat_weeks{

    bottom: 5px;
    max-width: 100%;
    left: 0;
    padding: 10px;
    z-index: 2;
    right: 0;
    transform: translateX(0%);
    margin-left: 0!important;
  }

}
 
/* overlay-setup */

.overlay-setup{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--un-background-color);
  z-index: 999999;  
  max-height: 60vh;
  padding: 1rem;
  box-shadow: 0 0 20px #00000059;
}

.overlay-setup :deep(.comments){
  max-height: 400px;
}

/* overlay-setup */

.overlay-nutrients{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--un-background-color);
  z-index: 999999;  
  max-height: 60vh;
  padding: 1rem;
  box-shadow: 0 0 20px #00000059;
}

.overlay-nutrients :deep(.comments){
  max-height: 400px;
}



.problems{
  width: 100%;
}
</style>
